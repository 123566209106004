import { Switch } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deleteBatch, getEnrolment, showRecordings, updateBatch } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import ModalForm from 'app/components/ModalForm'
import {
  batchTypesMap,
  enrolmentTypes,
  indianStatesJson,
  tableColumnDefaultOption
} from 'app/config'
import constants from 'app/constants'
import { showToast, confirmDialog, eventEmitter, eventAlert } from 'app/helpers'
import { fetchBatchData, updateClassData } from 'app/store/actions/classEntity'
import { batchDataTransformer } from 'app/transformer'

import { BatchActivitiesWidget } from './BatchActivitiesWidget'
import { columns } from './config'
import { EnrolmentsTable } from './EnrolmentsTable'
import MasterForm from './MasterForm'
import { RecordingsTable } from './RecordingsTable'

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

const Batch = () => {
  const batchData = batchDataTransformer(
    useSelector((state) => state.classEntityReducer.batchData)
  )
  const [modalOpen, setModalOpen] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [enrolmentsModalOpen, setEnrolmentsModalOpen] = React.useState(false)
  const [errors, setErrors] = React.useState('')
  const [action, setAction] = React.useState('')
  const [tableData, setTableData] = React.useState('')
  const [enrolmentsData, setEnrolmentsData] = React.useState('')
  const [handler, setHandler] = React.useState(() => {})
  const [editIndex, setEditIndex] = React.useState(null)
  const dispatch = useDispatch()

  const statusList = ['active', 'upcoming']

  const openModal = React.useCallback((e, action, dataIndex) => {
    // e.preventDefault()
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    openModal(e, action, dataIndex)
  }

  const onSaveClick = async (newData, action) => {
    try {
      handler({ reason: newData.reason })

      eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      closeModal()
      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(fetchBatchData({ ...queries }, false, false, constants.PARENT))
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const handleShowRecordings = React.useCallback(
    (dataIndex) => {
      const _data = batchData.data[dataIndex]
      showRecordings(_data)
        .then((data) => {
          if (data.results) {
            setTableData(
              data.results.map((schedule) => ({
                date: schedule.date,
                recordingUrl: schedule.recording_url
              }))
            )
            setModalOpen(true)
          } else {
            showToast('Oops, Unable to fetch data', constants.ERROR)
          }
        })
        .catch((e) => {
          dispatch(fetchBatchData({}, constants.BATCH))
          setErrors(e.message ? e.message : 'Unable to fetch recordings')
          showToast(
            e.message ? e.message : 'Unable to fetch recordings',
            constants.ERROR
          )
        })
    },
    [batchData, showRecordings, dispatch]
  )

  const getEnrolmentDetails = React.useCallback(
    (dataIndex) => {
      const data = batchData.data[dataIndex]
      getEnrolment({ batchId: data.uuid })
        .then((data) => {
          if (!isEmpty(data)) {
            setEnrolmentsData(
              data?.enrolment?.map((enrolment) => {
                if (
                  statusList.includes(enrolment?.status) &&
                  enrolment?.type !== enrolmentTypes.MAKE_UP_SESSION
                ) {
                  return {
                    name: enrolment?.student?.user?.full_name,
                    standard: enrolment?.student?.standard?.name,
                    start_date: dayjs(enrolment?.start_date).format(
                      'DD MMM YY'
                    ),
                    end_date: dayjs(enrolment?.end_date).format('DD MMM YY'),
                    status: enrolment?.status,
                    parent_name: enrolment?.student?.parent
                      ? `${enrolment?.student?.parent?.user?.first_name} ${enrolment?.student?.parent?.user?.last_name}`
                      : null,
                    parent_number: enrolment?.student?.parent
                      ? enrolment?.student?.parent?.user?.phone_number
                      : null
                  }
                }
              })
            )
            setEnrolmentsModalOpen(true)
          } else {
            showToast('Oops, Unable to fetch data', constants.ERROR)
          }
        })
        .catch((e) => {
          setErrors(e.message ? e.message : 'Unable to fetch enrolments')
          showToast(
            e.message ? e.message : 'Unable to fetch enrolments',
            constants.ERROR
          )
        })
    },
    [batchData]
  )

  const onChangePublish = (batchData, enabled) => {
    dispatch(
      updateClassData(constants.BATCH, constants.UPDATED, {
        uuid: batchData.uuid,
        publish: enabled
      })
    )
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'schedule_event.schedules',
        label: 'Batch/Schedule Timings',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'event_timings',
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '500px',
              maxWidth: '500px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = batchData.data[dataIndex]
            return data.type === batchTypesMap.event ||
              data.type === batchTypesMap.explore ||
              data.type === batchTypesMap.exploration ? (
              <span>
                {Array.isArray(data?.schedule_event?.schedules) &&
                  data?.schedule_event?.schedules
                    .map((item) => {
                      const start = item.start_time.split('-').join(':')
                      const end = item.end_time.split('-').join(':')
                      return `${dayjs(item.date).format('ddd DD MMM')}, ${dayjs(
                        '1/1/1 ' + start
                      ).format('h:mma')} - ${dayjs('1/1/1 ' + end).format(
                        'h:mma'
                      )}`
                    })
                    .join(' | ')}
              </span>
            ) : (
              <span>
                {Array.isArray(data.cadence) &&
                  data.cadence
                    .sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
                    .map((item) => {
                      const start = item.start_time.split('-').join(':')
                      const end = item.end_time.split('-').join(':')
                      return `${dayjs()
                        .day(
                          item.weekday >= 0 && item.weekday < 7
                            ? item.weekday + 1
                            : 7
                        )
                        .format('ddd')}, ${dayjs('1/1/1 ' + start).format(
                        'h:mma'
                      )} - ${dayjs('1/1/1 ' + end).format('h:mma')}`
                    })
                    .join(' | ')}
              </span>
            )
          }
        }
      },
      {
        name: 'shopify_variant_id',
        label: 'Variant ID',
        optional: true,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true
        }
      },
      // {
      //   name: 'publish',
      //   label: 'Publish to app',
      //   disabledInForm: true,
      //   options: {
      //     disableFilter: true,
      //     customBodyRenderLite: (dataIndex) => {
      //       const data = batchData.data[dataIndex]
      //       return (
      //         <Switch
      //           key={data.uuid}
      //           checked={data.publish}
      //           color="primary"
      //           onChange={(event) =>
      //             onChangePublish(data, event.target.checked)
      //           }
      //         />
      //       )
      //     }
      //   }
      // },
      {
        name: '',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const data = batchData.data[dataIndex]
            return (
              <ButtonGroup>
                {data?.batch_mode === 'oh_meets' ? (
                  <Button onClick={() => handleShowRecordings(dataIndex)}>
                    Show Recordings
                  </Button>
                ) : null}
              </ButtonGroup>
            )
          }
        }
      },
      {
        name: '',
        disabledInForm: true,
        options: {
          customBodyRenderLite: (dataIndex) => {
            const data = batchData.data[dataIndex]

            return data?.enrolment_count > 0 ? (
              <Button onClick={() => getEnrolmentDetails(dataIndex)}>
                See enrolments
              </Button>
            ) : null
          }
        }
      }
    ]
  }, [batchData])

  const customHandleDelete = async (
    handlerFunction = () => {},
    rowsDeleted
  ) => {
    try {
      const data = batchData.data[rowsDeleted?.data[0]?.dataIndex]
      if (data.enrolment_count > 0) handlerFunction()
      else {
        onActionButtonClick(null, 'delete', rowsDeleted?.data[0]?.dataIndex)

        setHandler(() => (val) => handlerFunction(val))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <BaseEntity
        entity={constants.BATCH}
        label={constants.BATCH_LABEL}
        columns={modifiedColumns}
        data={batchData}
        fetchData={fetchBatchData}
        updateEntityData={updateClassData}
        customHandleDelete={customHandleDelete}
        createDependency
        selectableRows="single"
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={modalOpen}
          onModalClose={() => setModalOpen(false)}
          enableSave={false}
          modalTitle="Recordings Data"
        >
          <RecordingsTable data={tableData} />
        </ModalForm>
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={enrolmentsModalOpen}
          onModalClose={() => setEnrolmentsModalOpen(false)}
          enableSave={false}
          modalTitle="Enrolments"
        >
          <EnrolmentsTable data={enrolmentsData} />
        </ModalForm>
      </ThemeProvider>
      <MasterForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={batchData?.data[editIndex]}
      />
    </>
  )
}

export default Batch
