import { Menu, MenuItem } from '@material-ui/core'
import dayjs from 'dayjs'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'

import { fetchUser, updateTicket } from 'api'
import {
  fetchParentChatMessages,
  markConversationResolved,
  fetchParentList
} from 'api/chat'
import Avatar from 'app/components/generic/Avatar'
import EmptyDataIcon from 'app/components/generic/EmptyDataIcon'
import History from 'app/components/generic/history/history'
import Loading from 'app/components/generic/Loading'
import PillList from 'app/components/generic/PillList'
import Slider from 'app/components/generic/Slider/slider'
import { navigationPaths } from 'app/config'
import constants, { TICKET_STATUS_OPTIONS } from 'app/constants.js'
import {
  eventAlert,
  eventEmitter,
  getLoggedInUserName,
  showToast,
  confirm
} from 'app/helpers'
import FlagsComponent from 'app/pages/Dashboard/ParentDashboard/components/MembershipBlock/FlagsComponent'
import { dataColumns } from 'app/pages/Dashboard/ParentDashboard/config'
import { getTicketsData } from 'app/pages/Dashboard/ParentDashboard/helpers'
import MasterForm from 'app/pages/User/Parent/MasterForm'
import { selectParentChatMessagesHeaderData } from 'app/store/selectors/communicationEntity/chat'
import { useWebSocket } from 'app/WebSocketProvider'
import { sentryLogger } from 'sentry'

import * as styles from './styles'

import { newTab, scrollToBottom, studentIcon, threeDots } from '../../constants'
import {
  createMessageObject,
  createWSMessageObject,
  getStudentNameStandard
} from '../../helpers'
import MessagePoh from '../MessageBubble/MessagePoh'
import MessageComponent from '../MessageComponent'
import Textbox from '../Textbox'

const ParentMessageList = (props) => {
  const {
    selectedChat,
    selectedPill,
    searchValue,
    setParentListData,
    setParentListNextUrl,
    setParentListHasMore
  } = props

  const chatMessagesHeaderData = useSelector(selectParentChatMessagesHeaderData)
  const chatHeaderData = chatMessagesHeaderData?.parentChatMessagesHeaderData

  const [chatMessages, setChatMessages] = React.useState([])
  const [chatHeader, setChatHeader] = React.useState([])
  const [currentChatUuid, setCurrentChatUuid] = React.useState(
    chatHeaderData.uuid
  )
  const [textMessage, setTextMessage] = React.useState('')
  const [hasMoreData, setHasMoreData] = React.useState(true)
  const [nextUrl, setNextUrl] = React.useState(null)
  const [openTicketsData, setOpenTicketsData] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(false)
  const [modalFormData, setModalFormData] = React.useState({})
  const [action, setAction] = React.useState('')
  const [userDetails, setUserDetails] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isResolved, setIsResolved] = React.useState(true)
  const currentChatUuidRef = React.useRef(null)
  const [showScrollButton, setShowScrollButton] = React.useState(false)

  const userOpenIssues = userDetails[0]?.user?.in_progress_ticket_count
  const userFullName =
    userDetails[0]?.user?.first_name + ' ' + userDetails[0]?.user?.last_name
  const userPhoneNumber = userDetails[0]?.user?.phone_number
  const userId = userDetails[0]?.user?.user_id
  const studentPillDetails = getStudentNameStandard(userDetails[0]?.students)

  const {
    sendDataOnSocket,
    addMessageListener,
    removeMessageListener,
    clearPresenceTimer
  } = useWebSocket()
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  React.useEffect(() => {
    const element = document.getElementById('scrollableDiv')

    const handleScroll = () => {
      if (!element) return

      // Since the container uses flex column-reverse
      // we need to check from the top (which is actually bottom)
      const isNearBottom = element.scrollTop > -50
      setShowScrollButton(!isNearBottom)
    }

    // Initial check
    handleScroll()

    // Add scroll listener
    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const onScrollToBottomClick = (smooth = false) => {
    const element = document.getElementById('scrollableDiv')

    if (smooth === true) {
      const startPosition = element.scrollTop
      const targetPosition = element.scrollHeight
      const distance = targetPosition - startPosition
      const duration = 2000
      let startTime = null

      function animation(currentTime) {
        if (startTime === null) startTime = currentTime
        const timeElapsed = currentTime - startTime
        const progress = Math.min(timeElapsed / duration, 1)

        // Easing function for smooth animation
        const easeOutQuart = (t) => 1 - Math.pow(1 - t, 4)
        const run = startPosition + distance * easeOutQuart(progress)

        element.scrollTop = run

        if (progress < 1) {
          requestAnimationFrame(animation)
        }
      }
      requestAnimationFrame(animation)
    } else {
      //instant scroll
      element.scrollTop = element.scrollHeight
    }
  }

  const sendMessageData = async () => {
    onScrollToBottomClick()
    const loggedInUser = getLoggedInUserName()
    const dataMessage = createMessageObject(textMessage, 'text', loggedInUser)
    const wsMessageObject = createWSMessageObject(
      textMessage,
      'text',
      chatHeaderData?.uuid
    )
    sendDataOnSocket(wsMessageObject)
    setChatMessages((prevData) => [dataMessage, ...prevData])

    sendDataOnSocket({ presence: true, channel_id: chatHeaderData?.uuid }) //so that BE can mark read receipt as true
    await delay(3000) // delay so that BE can update read receipts for that message
    const data = await fetchParentList({
      queries: {
        centre_uuid: JSON.parse(
          localStorage.getItem('SELECTED_CENTRE_PARENT_CHAT_CENTRE')
        )?.value,
        ...(selectedPill !== 'all' && { conversation_status: selectedPill }),
        search: searchValue,
        page_size: 10
      }
    })
    setParentListData(data.results)
    setParentListNextUrl(data.next)
    setParentListHasMore(!!data.next)
  }

  const onError = () => {
    eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
  }

  const onSaveClick = async (newData, action) => {
    try {
      const formData = { ...newData, ...modalFormData }

      const res = await updateTicket({
        user: formData?.uuid,
        status: newData?.status,
        resolution_summary: newData?.resolution_summary,
        uuid: formData?.ticketId,
        follow_up_date: newData?.follow_up_date
      })

      if (
        res?.success ||
        res?.subscription?.uuid ||
        res?.uuid ||
        res?.user_id ||
        res?.title
      ) {
        eventAlert('Done', constants.SUCCESS)
        setOpenModal(false)
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      }
    } catch (error) {
      console.log(error)
      sentryLogger(error, 'Error in onSaveClick of parent message list')
      eventAlert(error.message, constants.ERROR)
      setOpenModal(false)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
    }
  }

  const markChatResolved = async (uuid) => {
    const response = await markConversationResolved(uuid)
    if (response?.conversation_status === 'resolved') {
      showToast('chat resolved successfully', constants.SUCCESS)
      setIsResolved(true)
    }
  }

  const fetchMessages = async (resetMessages = false) => {
    try {
      setLoading(true)
      const data = await fetchParentChatMessages({
        queries: {
          chat_uuid: chatHeaderData?.uuid,
          page_size: 10
        },
        url: chatHeaderData?.uuid === currentChatUuid ? nextUrl : null
      })

      // if it's a new chat (UUID changed), reset messages
      if (chatHeaderData?.uuid !== currentChatUuid || resetMessages) {
        setChatMessages(data.results)
        setCurrentChatUuid(chatHeaderData?.uuid)
        setNextUrl(data.next)
        setHasMoreData(!!data.next)
      } else {
        // append new messages for paginated results
        setChatMessages((prevMessages) => [...prevMessages, ...data.results])
        setNextUrl(data.next)
        setHasMoreData(!!data.next)
      }
    } catch (error) {
      sentryLogger(error, 'Failed to fetch parent chat messages')
      console.error('Failed to fetch parent chat messages')
    } finally {
      setLoading(false)
    }
  }

  // function to open edit modal from the tickets slider
  const openForm = React.useCallback(({ event, action, actionData }) => {
    if (event) {
      event.preventDefault()
    }
    setAction(action)
    setOpenModal(true)
    setModalFormData(actionData)
  }, [])

  React.useEffect(() => {
    // sync ref with the latest chat uuid value
    currentChatUuidRef.current = currentChatUuid
    setTextMessage('')
  }, [currentChatUuid])

  // adding a listener to read incoming messages via WS
  React.useEffect(() => {
    const messageListener = (message) => {
      const parsedMessage = JSON.parse(message)
      const currentUuid = currentChatUuidRef.current

      const updatedMessage =
        parsedMessage?.member_type?.toLowerCase() === 'admin'
          ? {
              ...parsedMessage,
              author_type: 'admin'
            }
          : {
              ...parsedMessage
            }

      if (
        updatedMessage?.presence &&
        updatedMessage?.channel_id === currentUuid
      ) {
        setChatMessages((prevData) =>
          prevData.map((msg) =>
            msg?.status !== 'read' &&
            updatedMessage?.member_type?.toLowerCase() === 'parent'
              ? { ...msg, status: 'read' }
              : msg
          )
        )
      }

      if (updatedMessage?.uuid && updatedMessage?.channel_id === currentUuid) {
        setChatMessages((prevData) => [updatedMessage, ...prevData])
      }
    }
    addMessageListener(messageListener)

    return () => {
      removeMessageListener(messageListener)
      console.log('WS message listener removed')
      clearPresenceTimer()
    }
  }, [])

  // function to get initial tickets data that will also run on refreshing tickets slider
  React.useEffect(() => {
    ;(async () => {
      if (chatHeader.parent_number) {
        const data = await getTicketsData(chatHeader.parent_number, openForm)
        const openTickets = data?.filter(
          (ticket) => ticket.ticket_status !== TICKET_STATUS_OPTIONS.RESOLVED
        )
        setOpenTicketsData(openTickets)
      }
    })()
  }, [chatHeader])

  React.useEffect(() => {
    setChatHeader(chatHeaderData)
    if (chatHeaderData.uuid) {
      fetchMessages(true) // true is passed so that if a user clicks the same chat, messages get reset and not appended
    }
  }, [chatHeaderData])

  React.useEffect(() => {
    if (chatHeaderData.conversation_status === 'open') {
      setIsResolved(false)
    } else {
      setIsResolved(true)
    }
  }, [chatHeaderData])

  React.useEffect(() => {
    const getUserDetails = async () => {
      if (chatHeader.parent_id) {
        const data = await fetchUser(
          { queries: { uuid: chatHeader?.parent_id } },
          constants.PARENT
        )
        setUserDetails(data.results)
      }
    }
    getUserDetails()
  }, [chatHeader])

  return (
    <>
      <MasterForm
        isModalOpen={openModal}
        onSaveClick={onSaveClick}
        closeModal={() => {
          setOpenModal(false)
        }}
        action={action}
        data={null}
        extraFormData={modalFormData}
      />
      <styles.MessageListContainer>
        <styles.HeaderContainer>
          {(chatHeaderData?.url || chatHeaderData?.name) && (
            <Avatar
              thumbnailUrl={null}
              name={chatHeaderData?.name}
              avatarImageStyles={styles.avatarImageStyles}
              avatarTextStyles={styles.avatarTextStyles}
            />
          )}
          <styles.HeaderDetails>
            <styles.HeaderText>
              {chatHeaderData?.name}
              {chatHeaderData?.parent_number && (
                <styles.PhoneLink
                  onClick={() =>
                    window.open(
                      `${window.location.origin}${navigationPaths.parentdashboard}?parent=${chatHeaderData?.parent_id}`
                    )
                  }
                >
                  {chatHeaderData?.parent_number}
                  <styles.NewTabIcon src={newTab} />
                </styles.PhoneLink>
              )}
            </styles.HeaderText>
            <PillList
              data={studentPillDetails}
              pillStyles={styles.PillStyles}
              truncationLength={100}
              singleIconList={true}
              singleIconUrl={studentIcon}
            />
            {chatHeaderData?.name && (
              <div style={styles.FlagStyles}>
                <FlagsComponent
                  flags={userDetails[0]?.crm?.flags}
                  flagBoxStyles={styles.FlagBoxStyles}
                />
              </div>
            )}
          </styles.HeaderDetails>

          {chatHeaderData?.name && (
            <styles.TicketContainer>
              <button
                style={styles.button}
                onClick={() =>
                  window.open(
                    `${window.location.origin}${navigationPaths.ticket}?openModalForm=1&parent=${chatHeaderData?.parent_id}`
                  )
                }
              >
                + new ticket
              </button>

              <Slider
                width="50%"
                key={'SliderTicketOpen'}
                placeholder={
                  <div style={styles.issueText}>
                    {userOpenIssues} open issues
                  </div>
                }
                blockType={'tickets log'}
                childComponent={
                  <History
                    type={dataColumns['tickets log']?.type}
                    heading={dataColumns['tickets log']?.heading}
                    data={openTicketsData}
                    userInfo={{
                      full_name: userFullName,
                      uuid: userId,
                      phone_number: userPhoneNumber
                    }}
                    tableHeadings={dataColumns['tickets log']?.tableHeadings}
                    accessors={dataColumns['tickets log']?.accessors}
                    readOnly={true}
                    editableDetails={false}
                    addDetails={false}
                    headerData={true}
                  />
                }
              />
              <div>
                <div onClick={(event) => setAnchorEl(event.currentTarget)}>
                  <styles.SeeMore src={threeDots} alt="..." />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  style={{ marginTop: 40 }}
                >
                  <MenuItem
                    disabled={isResolved ? true : false}
                    onClick={() => {
                      confirm(
                        () => {
                          markChatResolved(currentChatUuid)
                        },
                        'You want to mark this chat as resolved? This action is final and cannot be changed.',
                        onError
                      )
                      setAnchorEl(null)
                    }}
                    style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}
                  >
                    mark as resolved
                  </MenuItem>
                </Menu>
              </div>
            </styles.TicketContainer>
          )}
        </styles.HeaderContainer>
        <styles.BodyContainer
          id="scrollableDiv"
          style={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse'
          }}
        >
          {chatMessages.length > 0 ? (
            <InfiniteScroll
              dataLength={chatMessages.length}
              next={fetchMessages}
              hasMore={hasMoreData}
              loader={loading ? <Loading /> : null}
              inverse={true}
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              scrollableTarget="scrollableDiv"
            >
              {chatMessages.map((item, index) =>
                item?.content?.type === 'poh_v2' ? (
                  <MessagePoh
                    key={index}
                    iconUrl={item?.author_icon}
                    messageText={item?.content?.body}
                    timestamp={dayjs(item?.created).format(
                      'hh:mm A • ddd • DD MMM YYYY'
                    )}
                    ctaText={item?.content?.cta[0]?.label}
                  />
                ) : (
                  <MessageComponent
                    key={index}
                    timestamp={dayjs(item?.created).format(
                      'hh:mm A • ddd • DD MMM YYYY'
                    )}
                    authorType={item?.author_type}
                    authorIcon={null}
                    authorName={item?.author_name}
                    status={item?.status}
                    contentText={item?.content?.body}
                    contentType={item?.content?.type}
                    contentMedia={item?.content?.media?.value}
                    contentCta={item?.content?.cta}
                    avatarTextStyles={styles.avatarMessageTextStyles}
                    showOnlyAdminReadReceipts={true}
                  />
                )
              )}
            </InfiniteScroll>
          ) : (
            <EmptyDataIcon />
          )}
        </styles.BodyContainer>
        <styles.BodyWrapper>
          {selectedChat && showScrollButton && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <styles.ScrollToBottom
                src={scrollToBottom}
                onClick={() => {
                  onScrollToBottomClick(true)
                }}
              />
            </div>
          )}
          {selectedChat && (
            <Textbox
              textMessage={textMessage}
              setTextMessage={setTextMessage}
              sendMessageData={sendMessageData}
            />
          )}
        </styles.BodyWrapper>
      </styles.MessageListContainer>
    </>
  )
}

export default ParentMessageList
