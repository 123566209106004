import quillEmoji from 'quill-emoji'
import React from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import * as styles from './styles'
import 'quill-emoji/dist/quill-emoji.css'

const Quill = ReactQuill.Quill
const FontAttributor = Quill.import('attributors/class/font')
//add your font family names in this array
FontAttributor.whitelist = ['Avenir']
Quill.register(FontAttributor, true)
Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    // 'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji
  },
  true
)
// const toolbarOptions = [
//   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
//   ['blockquote', 'code-block'],
//   ['link', 'image', 'video', 'formula'],

//   [{ header: 1 }, { header: 2 }], // custom button values
//   [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
//   [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
//   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
//   [{ direction: 'rtl' }], // text direction

//   [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
//   [{ header: [1, 2, 3, 4, 5, 6, false] }],

//   [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//   [{ font: [] }],
//   [{ align: [] }],

//   ['clean'] // remove formatting button
// ]

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { script: 'sub' },
        { script: 'super' }
      ],
      [{ align: [] }],
      ['link'],
      ['emoji'],
      ['clean']
    ],
    handlers: { emoji: function () {} }
  },
  'emoji-toolbar': true,
  'emoji-textarea': false,
  'emoji-shortname': false
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'script',
  'align',
  'list',
  'bullet',
  'indent',
  'link',
  'emoji'
]

function RichTextEditor({ value, onChange }) {
  return (
    <styles.Container>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder="Type your message here..."
        style={{ width: '99%' }}
        preserveWhitespace={true}
        modules={modules}
        formats={formats}
      />
    </styles.Container>
  )
}

export default RichTextEditor
