import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import RichTextEditor from 'app/components/generic/RichTextEditor'

import * as styles from './styles'

import { sendButton } from '../../constants'

const Textbox = ({ textMessage, setTextMessage, sendMessageData }) => {
  const useStyles = makeStyles({
    noBorder: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none'
        },
        '&.Mui-focused fieldset': {
          border: 'none'
        }
      },
      '& .MuiInputBase-root': {
        fontSize: '14px'
      }
    }
  })

  const classes = useStyles()

  const handleClearText = () => {
    setTextMessage('')
  }

  const onSendButtonPress = () => {
    if (textMessage.trim() !== '') {
      console.log(textMessage.trim())
      sendMessageData()
      handleClearText()
    }
  }

  const handleKeyDown = (e) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      !e.altKey &&
      !e.metaKey &&
      !e.ctrlKey
    ) {
      // only send message if it's not empty
      if (textMessage.trim() !== '') {
        sendMessageData()
        handleClearText()
      }
      e.preventDefault()
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px',
        paddingLeft: '16px',
        paddingRight: '18px',
        width: '100%'
      }}
    >
      {/* <styles.StyledTextField
        value={textMessage}
        onChange={(e) => setTextMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        multiline
        maxRows={7}
        variant="outlined"
        placeholder="type your message here..."
        fullWidth
        InputProps={{
          style: { padding: '12px', maxWidth: '100%', border: 'none' }
        }}
        className={classes.noBorder}
      /> */}
      <RichTextEditor value={textMessage} onChange={setTextMessage} />
      <styles.SendButtonContainer onClick={onSendButtonPress}>
        <styles.SendButtonImage src={sendButton} alt="send" />
      </styles.SendButtonContainer>
    </div>
  )
}

export default Textbox
