import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .ql-container {
    font-family: 'Avenir';
    border-radius: 0 0 8px 8px;
    min-height: 50px;
  }

  .ql-toolbar {
    border-radius: 8px 8px 0 0;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    bottom: 100%;
    top: auto;
  }

  #emoji-palette {
    bottom: 100%;
    top: auto !important;
  }
`
