import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const TextboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 1;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  position: sticky;
  bottom: 0;
`

export const StyledTextField = styled(TextField)`
  flex-grow: 1;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
  textarea {
    top: 0;
    left: 0;
    position: inherit;
  }
`

export const SendButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
`

export const SendButtonImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 15px;
`
