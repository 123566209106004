import { Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import ParentMembershipIcon from '@material-ui/icons/AccountBox'
import InventoryIcon from '@material-ui/icons/Archive'
import PortfolioIcon from '@material-ui/icons/BusinessCenterOutlined'
import CentreScheduleIcon from '@material-ui/icons/CalendarToday'
import ChatIcon from '@material-ui/icons/Chat'
import ClassIcon from '@material-ui/icons/Class'
import MocksIcon from '@material-ui/icons/Create'
import ParentDashboardIcon from '@material-ui/icons/Dashboard'
import DescriptionRounded from '@material-ui/icons/DescriptionRounded'
import DvrIcon from '@material-ui/icons/Dvr'
import EventIcon from '@material-ui/icons/Event'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import EnrolmentIcon from '@material-ui/icons/HowToReg'
import LanguageIcon from '@material-ui/icons/Language'
import AttendanceIcon from '@material-ui/icons/LibraryAddCheck'
import HelpIcon from '@material-ui/icons/LiveHelp'
import PaymentIcon from '@material-ui/icons/Payment'
import PeopleIcon from '@material-ui/icons/People'
import RoomIcon from '@material-ui/icons/Room'
import ScheduleIcon from '@material-ui/icons/Schedule'
import ProgressTrackingIcon from '@material-ui/icons/TrackChanges'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { fetchUnreadCount } from 'api/chat'
import { drawerWidth, navigationMenus, navigationPaths } from 'app/config'
import { SUPPORT_FORM_LINK } from 'app/constants'
import { getLoggedInUserName } from 'app/helpers'
import { updateDrawerNavigationMenuState } from 'app/store/actions/app'
import { dispatchLogoutStart } from 'app/store/dispatcher/auth'
import { useWebSocket } from 'app/WebSocketProvider'

const menuIcons = {
  Chat: <ChatIcon color="secondary" />,
  Class: <ClassIcon color="secondary" />,
  Inventory: <InventoryIcon color="secondary" />,
  Enrolment: <EnrolmentIcon color="secondary" />,
  Language: <LanguageIcon color="secondary" />,
  Payment: <PaymentIcon color="secondary" />,
  People: <PeopleIcon color="secondary" />,
  Room: <RoomIcon color="secondary" />,
  Event: <EventIcon color="secondary" />,
  Logout: <LogoutIcon color="secondary" />,
  Scheduler: <ScheduleIcon color="secondary" />,
  CentreSchedule: <CentreScheduleIcon color="secondary" />,
  Reports: <DescriptionRounded color="secondary" />,
  BookDemo: <DvrIcon color="secondary" />,
  Mock: <MocksIcon color="secondary" />,
  Portfolio: <PortfolioIcon color="secondary" />,
  ParentMembership: <ParentMembershipIcon color="secondary" />,
  Parentdashboard: <ParentDashboardIcon color="secondary" />,
  Attendance: <AttendanceIcon color="secondary" />,
  ProgressTracking: <ProgressTrackingIcon color="secondary" />,
  Help: <HelpIcon color="secondary" />
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawerContainer: {
      width: '100%'
    },
    list: {
      width: '100%',
      color: theme.palette.secondary.main
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    icon: {
      minWidth: '40px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.background.dark,
      borderRight: 'none'
    },
    content: {
      flexGrow: 1
    },
    nested: {
      paddingLeft: theme.spacing(5)
    },
    doubleNested: {
      paddingLeft: theme.spacing(10)
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 22,
      color: '#FFFFFF',
      marginBottom: 0,
      marginTop: 5
    },
    version: {
      fontSize: 22,
      fontWeight: 'bold',
      marginLeft: 5
    },
    firstName: {
      fontSize: 28,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      marginTop: '-4px'
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: theme.spacing(2)
    },
    selected: {
      color: theme.palette.primary.main
    },
    appbarContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1
    },
    themeProfileContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    headerName: {
      fontWeight: 'bold'
    },
    unreadCount: {
      backgroundColor: '#EB5757',
      borderRadius: '50%',
      width: '1rem',
      height: '1rem',
      marginRight: '1.5rem'
    }
  })
)

const MainLayout = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [path, setPath] = React.useState('')

  const { addMessageListener, removeMessageListener } = useWebSocket()

  const drawerNavigationMenuState = useSelector(
    (state) => state.appReducer.drawerNavigationMenuState
  )

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const loggedInUser = getLoggedInUserName()
  const firstName = loggedInUser
    ? loggedInUser?.split(' ')[0]?.toLowerCase()
    : 'user'

  const [unreadCountNumber, setUnreadCountNumber] = React.useState(0)
  const unreadCountRef = useRef(null)
  unreadCountRef.current = unreadCountNumber

  React.useEffect(() => {
    setPath(location.pathname)
  }, [location])

  React.useEffect(() => {
    const fetchUnreadCountData = async () => {
      const data = await fetchUnreadCount()
      setUnreadCountNumber(data.unread_message_count)
    }

    const timeout = setTimeout(() => {
      fetchUnreadCountData()
    }, 1500)

    return () => clearTimeout(timeout)
  }, [])

  // adding a listener to for unread count updates via WS
  React.useEffect(() => {
    const unreadCountListener = (message) => {
      const parsedMessage = JSON.parse(message)

      if (unreadCountRef.current !== parseInt(parsedMessage.total_unread_count))
        setUnreadCountNumber(parsedMessage.total_unread_count)
    }
    addMessageListener(unreadCountListener)

    return () => {
      removeMessageListener(unreadCountListener)
      console.log('unread count listener removed from mainlayout.js')
    }
  }, [])

  const activeRoute = React.useCallback(
    (route) => {
      return route === path
    },
    [path]
  )

  const handleClick = React.useCallback(
    (menu) => {
      const { nested, name: menuKey } = menu

      if (nested) {
        dispatch(
          updateDrawerNavigationMenuState({
            ...drawerNavigationMenuState,
            [menuKey]: !drawerNavigationMenuState[menuKey]
          })
        )
      } else {
        dispatch(
          updateDrawerNavigationMenuState({
            [menuKey]: !drawerNavigationMenuState[menuKey]
          })
        )
      }
    },
    [dispatch, drawerNavigationMenuState]
  )

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const renderMenuElement = React.useCallback(
    (menu) => {
      return menu.map((item, index) => {
        if (!item.subMenus) {
          if (item.sideBarVisibility === false) {
            return null
          }

          const selected = activeRoute(item.path)
          return (
            <MenuItem
              button
              key={index}
              component={Link}
              to={item.path}
              className={clsx(
                classes.list,
                selected ? classes.selected : null,
                item.doubleNested
                  ? classes.doubleNested
                  : item.nested === false
                  ? null
                  : classes.nested
              )}
              selected={selected}>
              {item.icon && !isEmpty(item.icon.name) ? (
                <>
                  <ListItemIcon className={clsx(classes.icon)}>
                    {menuIcons[item.icon.name] || null}
                  </ListItemIcon>
                  <ListItemText primary={item.displayName.toLowerCase()} />
                </>
              ) : (
                <>
                  <ListItemText primary={item.displayName.toLowerCase()} />
                  {item.displayName.toLowerCase() === 'centre-parent chat' &&
                    unreadCountNumber > 0 && (
                      <div className={classes.unreadCount}></div>
                    )}
                </>
              )}
            </MenuItem>
          )
        }

        return item.sideBarVisibility === false ? null : (
          <React.Fragment key={index}>
            <MenuItem
              button
              onClick={() => handleClick(item)}
              className={clsx(
                classes.list,
                item.nested ? classes.nested : null
              )}>
              {item.icon && !isEmpty(item.icon.name) ? (
                <>
                  <ListItemIcon className={clsx(classes.icon)}>
                    {menuIcons[item.icon.name] || null}
                  </ListItemIcon>
                  <ListItemText primary={item.displayName.toLowerCase()} />
                  {item.displayName.toLowerCase() === 'communication' &&
                    unreadCountNumber > 0 && (
                      <div className={classes.unreadCount}></div>
                    )}
                </>
              ) : (
                <ListItemText primary={item.displayName.toLowerCase()} />
              )}
              {drawerNavigationMenuState[item.name] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </MenuItem>
            <Collapse
              in={drawerNavigationMenuState[item.name]}
              timeout="auto"
              unmountOnExit>
              {renderMenuElement(item.subMenus)}
            </Collapse>
          </React.Fragment>
        )
      })
    },
    [
      drawerNavigationMenuState,
      classes,
      activeRoute,
      handleClick,
      unreadCountNumber
    ]
  )

  const handleLogout = (e) => {
    e.preventDefault()
    dispatchLogoutStart()
  }

  const handleHelpButtonPress = (e) => {
    e.preventDefault()
    window.location.href = SUPPORT_FORM_LINK
  }

  const handleScheduleClick = React.useCallback(
    (e) => {
      e.preventDefault()
      history.push({
        pathname: navigationPaths.schedule
      })
    },
    [history]
  )

  const drawer = React.useMemo(
    () => (
      <div>
        <div className={`${classes.toolbar} ${classes.logoContainer}`}>
          <Typography variant="h3" gutterBottom className={classes.logo}>
            welcome to <span className={classes.version}> alfred,</span>
          </Typography>
          <Typography className={classes.firstName}>
            {firstName + '!'}
          </Typography>
          {/* <ThemeSwitcher /> */}
        </div>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.drawerContainer}>
          {renderMenuElement(navigationMenus)}
          {/* <MenuItem
            button
            onClick={handleScheduleClick}
            className={classes.list}
          >
            <ListItemIcon>{menuIcons['CentreSchedule']}</ListItemIcon>
            <ListItemText primary="centre schedule" />
          </MenuItem> */}
          <MenuItem button onClick={handleLogout} className={classes.list}>
            <ListItemIcon>{menuIcons['Logout']}</ListItemIcon>
            <ListItemText primary="logout" />
          </MenuItem>

          <MenuItem
            button
            onClick={handleHelpButtonPress}
            className={classes.list}>
            <ListItemIcon>{menuIcons['Help']}</ListItemIcon>
            <ListItemText primary="get help " />
          </MenuItem>
        </List>
      </div>
    ),
    [renderMenuElement, classes, handleScheduleClick]
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node
}

export default MainLayout
