import React from 'react'

import { Attendance } from './components/Attendance'
import { Children } from './components/Children'
import { ClassroomExperience } from './components/ClassroomExperience'
import { Enrolments } from './components/Enrolments'
import { Interactions } from './components/Interactions'
import { Journeys } from './components/Journeys'
import { LifeTimeHealth } from './components/LifeTimeHealth'
import { ScheduleSuggestion } from './components/ScheduleSuggestion'
import { TicketsLog } from './components/TicketsLog'
import { Wallet } from './components/Wallet'
import * as styles from './styles'

export const SummaryBlock = (props) => {
  const { selectedParentDetails, summaryData, keyIndex, enrolmentData } = props
  const [isSelected, setIsSelected] = React.useState(false)

  return summaryData.fullWidth === true ? (
    <styles.LongWrapper>
      <styles.Header>
        <styles.CardName>
          {summaryData.icon}
          &nbsp;&nbsp;
          <styles.Heading>{`${summaryData.type}`}</styles.Heading>
        </styles.CardName>
      </styles.Header>
      {summaryData.type === 'interactions' ? (
        <Interactions interactionsData={summaryData} />
      ) : null}
    </styles.LongWrapper>
  ) : (
    <styles.Wrapper isSelected={isSelected} key={keyIndex}>
      <styles.Header>
        <styles.CardName>
          {summaryData.icon}
          &nbsp;&nbsp;
          <styles.Heading>{`${summaryData.type}`}</styles.Heading>
        </styles.CardName>
      </styles.Header>
      {summaryData.type === 'enrolments' ? (
        <Enrolments enrolmentData={summaryData.data} />
      ) : summaryData.type === 'children' ? (
        <Children
          selectedParentDetails={selectedParentDetails}
          childrenData={summaryData.data}
          enrolmentData={enrolmentData}
        />
      ) : summaryData.type === 'journeys' ? (
        <Journeys journeyData={summaryData.data} />
      ) : summaryData.type === 'wallet' ? (
        <Wallet
          selectedParentDetails={selectedParentDetails}
          walletData={summaryData.data}
        />
      ) : summaryData.type === 'monthly attendance' ? (
        <Attendance
          selectedParentDetails={selectedParentDetails}
          attendanceData={summaryData.data}
        />
      ) : summaryData.type === 'classroom experience' ? (
        <ClassroomExperience
          selectedParentDetails={selectedParentDetails}
          classroomExperienceData={summaryData.data}
        />
      ) : summaryData.type === 'Schedule Suggestion' ? (
        <ScheduleSuggestion suggestionData={summaryData} />
      ) : summaryData.type === 'lifetime health' ? (
        <LifeTimeHealth
          selectedParentDetails={selectedParentDetails}
          lifeTimeHealthData={summaryData}
        />
      ) : summaryData.type === 'tickets log' ? (
        <TicketsLog
          ticketsData={summaryData}
          selectedParentDetails={selectedParentDetails}
        />
      ) : null}
    </styles.Wrapper>
  )
}
