// const { uuid, user, conversation_history } = data.data[dataIndex]
import { navigationPaths } from 'app/config'

export const dataColumns = {
  enrolments: {
    type: 'ENROLMENTS',
    heading: 'Enrolment History',
    tableHeadings: [
      { label: 'Start Date', type: 'text' },
      { label: 'Course', type: 'text' },
      { label: 'Batch timing', type: 'text' },
      { label: 'Kid', type: 'text' },
      { label: 'Status', type: 'text' },
      { label: 'is Discovery', type: 'text' },
      { label: 'Sessions attended', type: 'text' },
      { label: 'Sessions absent', type: 'text' },
      { label: 'Sessions canceled', type: 'text' },
      { label: 'Type', type: 'text' },
      { label: 'End date', type: 'text' },
      { label: 'Reason', type: 'text' },
      { label: 'Actions', type: 'button' }
    ],
    accessors: [
      'start_date',
      'course_name',
      'batch_timing',
      'kid',
      'status',
      'isDiscovery',
      'sessions_attended',
      'sessions_absent',
      'sessions_canceled',
      'type',
      'terminated_on',
      'termination_reason',
      'actions'
    ]
  },
  children: {
    type: 'CHILDREN',
    heading: 'Enrolment History',
    tableHeadings: [
      { label: 'Type', type: 'text' },
      { label: 'Course', type: 'text' },
      { label: 'Sessions attended', type: 'text' },
      { label: 'Sessions absent', type: 'text' },
      { label: 'Sessions canceled', type: 'text' },
      { label: 'Status', type: 'text' },
      { label: 'Batch timings', type: 'text' },
      { label: 'Start date', type: 'text' },
      { label: 'End date', type: 'text' },
      { label: 'Termination notes', type: 'text' }
    ],

    accessors: [
      'type',
      'course',
      'sessions_attended',
      'sessions_absent',
      'sessions_canceled',
      'status',
      'batch_timings',
      'start_date',
      'end_date',
      'termination_notes'
    ]
  },
  wallet: {
    type: 'WALLET',
    heading: 'Transaction History',
    tableHeadings: [
      { label: 'Date of Transaction', type: 'text' },
      { label: 'Transaction Event', type: 'text' },
      { label: 'Details', type: 'text' },
      { label: 'Total Paid', type: 'text' },
      { label: 'Coins', type: 'text' },
      { label: 'Rupees', type: 'text' },
      { label: 'GST (on Rupee)', type: 'text' },
      { label: 'Closing Balance', type: 'text' },
      { label: 'Child', type: 'text' }, // TODO: array
      { label: 'Notes', type: 'text' }
    ],

    accessors: [
      'transaction_date',
      'event',
      'event_sub_type',
      'total_paid', //TODO: CHECK AND FIX ALL ACCESSORS
      'coins_paid',
      'rupees_paid',
      'gst',
      'closing_balance',
      'child',
      'notes'
    ]
  },
  // renewals: {
  //   type: 'RENEWALS',
  //   heading: 'Renewal Transaction History',
  //   tableHeadings: [
  //     { label: 'Paid on', type: 'text' },
  //     { label: 'Renewal due', type: 'text' },
  //     { label: 'Plan', type: 'text' },
  //     { label: 'Invoice Amount', type: 'text' },
  //     { label: 'Status', type: 'text' },
  //     { label: 'Sales SPOC', type: 'text' },
  //     { label: 'Collected by', type: 'text' },
  //     { label: 'Payment method', type: 'text' },
  //     { label: 'Notes', type: 'text' }
  //   ],

  //   accessors: [
  //     'date',
  //     'renewal_due',
  //     'plan',
  //     'invoice_amount',
  //     'status',
  //     'sales_spoc',
  //     'collected_by',
  //     'payment_method',
  //     'notes'
  //   ]
  // },
  attendance: {
    type: 'ATTENDANCE_DASHBOARD',
    heading: 'Enrolment History',
    tableHeadings: [
      { label: 'Date', type: 'text' },
      { label: 'Course', type: 'text' },
      { label: 'Kid', type: 'text' },
      { label: 'Sessions attended', type: 'text' },
      { label: 'Sessions absent', type: 'text' },
      { label: 'Sessions canceled', type: 'text' },
      // { label: 'Sessions upcoming', type: 'text' },
      { label: 'Status', type: 'text' },
      { label: 'Type', type: 'text' },
      { label: 'Actions', type: 'button' }
    ],
    accessors: [
      'start_date',
      'course_name',
      'kid',
      'sessions_attended',
      'sessions_absent',
      'sessions_canceled',
      // 'sessions_upcoming',
      'status',
      'type',
      'actions'
    ]
  },
  'classroom experience': {
    type: 'CLASSROOM_EXPERIENCE',
    heading: 'Enrolment History',
    tableHeadings: [
      { label: 'Course', type: 'text' },
      { label: 'Teacher', type: 'text' },
      { label: 'Kid', type: 'text' },
      { label: 'Overall Rating', type: 'text' },
      { label: 'Sessions rated', type: 'text' },
      { label: 'Sessions attended', type: 'text' },
      { label: 'Status', type: 'text' },
      { label: 'Type', type: 'text' },
      { label: 'Actions', type: 'button' }
    ],
    accessors: [
      'course_name',
      'teacher',
      'kid',
      'totalRatingComponent',
      'rated',
      'attended',
      'status',
      'type',
      'actions'
    ]
  },

  interactions: {
    type: 'INTERACTION_HISTORY',
    heading: 'Interaction History',
    tableHeadings: [
      { label: 'Interaction Date', type: 'text' },
      { label: 'Interaction Topics', type: 'text' },
      { label: 'Interaction Summary', type: 'custom' },
      { label: 'Interaction Channel', type: 'text' },
      { label: 'SPOC Name', type: 'text' }
    ],
    accessors: [
      'interaction_date',
      'interaction_topics',
      'interaction_summary',
      'interaction_channel',
      'created_by'
    ]
  },

  'tickets log': {
    type: 'TICKETS_LOG',
    heading: 'Tickets Log',
    tableHeadings: [
      { label: 'Reported Date', type: 'text' },
      { label: 'Ticket Status', type: 'text' },
      { label: 'Ticket Type', type: 'text' },
      { label: 'Ticket Description', type: 'truncated-text' },
      { label: 'Raised On', type: 'text' },
      { label: 'Follow Up Date', type: 'text' },
      { label: 'SPOC Name', type: 'text' },
      { label: 'Action', type: 'button' }
    ],
    accessors: [
      'reported_date',
      'ticket_status',
      'ticket_type',
      'ticket_description',
      'raised_on',
      'follow_up_date',
      'created_by',
      'actions'
    ]
  }

  // 'Schedule Suggestion': {
  //   type: 'SCHEDULE_SUGGESTION',
  //   heading: 'Schedule Suggestion',
  //   tableHeadings: [
  //     { label: 'Course', type: 'text' },
  //     { label: 'Child', type: 'text' },
  //     { label: 'Ideal Timings', type: 'text' },
  //     { label: 'Other Timings', type: 'text' },
  //     { label: 'Source', type: 'text' }
  //   ],
  //   accessors: [
  //     'course_name',
  //     'child',
  //     'ideal_timings',
  //     'other_timings',
  //     'source'
  //   ]
  // }
  // 'enrolments extension': {
  //   type: 'ENROLMENTS_EXTENSION',
  //   heading: 'Extensions Remaining',
  //   tableHeadings: [
  //     { label: 'End date', type: 'text' },
  //     { label: 'Student', type: 'text' },
  //     { label: 'SKU', type: 'text' },
  //     { label: 'Sessions Remaining', type: 'text' }
  //   ],
  //   accessors: [
  //     'upcoming_extension',
  //     'student_name',
  //     'sku',
  //     'sessions_remaining'
  //   ]
  // }
}

export const blocks = [
  {
    type: 'wallet',
    data: [],
    slider: true,
    headerData: true
  },
  {
    type: 'children',
    data: [],
    slider: false,
    headerData: false
  },
  {
    type: 'journeys',
    data: [],
    slider: false,
    headerData: false,
    link: navigationPaths.journeySelection
  },
  {
    type: 'enrolments',
    data: [],
    slider: true,
    headerData: false
  },
  // {
  //   type: 'renewals',
  //   data: [],
  //   slider: true,
  //   headerData: false
  // },
  // {
  //   type: 'classroom experience',
  //   data: [],
  //   slider: true,
  //   headerData: true
  // },
  {
    type: 'monthly attendance',
    data: [],
    slider: false,
    headerData: false
  },
  {
    type: 'lifetime health',
    data: [],
    slider: false,
    headerData: true
  },
  {
    type: 'tickets log',
    data: [],
    slider: false,
    headerData: true
  },

  // interactions has to be the last card (it spans the whole screen)
  {
    type: 'interactions',
    data: [],
    slider: true,
    headerData: true,
    fullWidth: true
  }

  // {
  //   type: 'engagement',
  //   data: [],
  //   slider: false,
  //   headerData: false
  // },
  // {
  //   type: 'enrolments extension',
  //   data: [],
  //   slider: true,
  //   headerData: false
  // }
]

export const planColor = {
  universal: '#58B7E6',
  holistic: '#ef5c34',
  explorer: '#F3C520',
  free: '#ef5c34'
}
