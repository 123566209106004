import React from 'react'

import * as styles from './styles'

const IconText = ({
  url,
  text,
  isDisplayIcon,
  isRightAligned,
  customStyle
}) => {
  return (
    <styles.Container isRightAligned={isRightAligned}>
      {isDisplayIcon ? (
        <styles.Icon src={url} isRightAligned={isRightAligned} />
      ) : null}
      <styles.Text style={customStyle} isRightAligned={isRightAligned}>
        {text}
      </styles.Text>
    </styles.Container>
  )
}

export default IconText
