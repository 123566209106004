import styled from 'styled-components'

export const Container = styled.div`
  white-space: pre-wrap;

  a {
    color: blue;
  }

  p {
    margin: 0;
  }

  .ql-editor {
    box-sizing: unset;
    line-height: unset;
    height: unset;
    outline: none;
    overflow-y: unset;
    padding: unset;
    tab-size: unset;
    -moz-tab-size: unset;
    text-align: unset;
    white-space: unset;
    word-wrap: unset;
  }
`
