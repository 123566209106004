import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { numberWithCommas } from '@openhouse-technologies/utils'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React, { useState } from 'react'
import isFloat from 'validator/lib/isFloat'
import isInt from 'validator/lib/isInt'

import { fetchAddCoinsData, fetchReasonsData, getCoinCalculation } from 'api'
import {
  Checkbox,
  Select,
  TextField
} from 'app/components/generic/FormElements'
import constants, {
  POLICY_ACKNOWLEDGMENT_ALFRED,
  RECHARGE_REASON,
  OVERRIDE_9K_NUMBERS,
  OVERRIDE_WALLET_TRANSFER_REFUND_NUMBERS
} from 'app/constants'
import { canAddReferralCode, getCookie } from 'app/helpers'
import {
  validateFee,
  validateNotEmpty,
  validatePolicyAcknowledgment,
  validateRange,
  validateReferralCode,
  validator
} from 'app/validators'

import { discountPercentCalculator, transformRechargeSlabs } from './helper'
import * as Styles from './styles'

const REFERRAL_CODE_ERROR =
  'Please enter a valid Referral Code or deselect the checkbox to proceed'

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start'
  },
  checkbox: { paddingTop: 0 },
  errorText: { marginLeft: 30 }
})

const TopupForm = (props) => {
  const classes = useStyles()
  const loggedInNumber = getCookie(constants.USER_NUMBER)
  const canRechargeLessThan9k = OVERRIDE_9K_NUMBERS.includes(loggedInNumber)
  const canRefundOrWalletTransfer =
    OVERRIDE_WALLET_TRANSFER_REFUND_NUMBERS.includes(loggedInNumber)

  const { formData, coinSummary, cartType } = props

  const { errors, values, setFieldValue, validateField } = useFormikContext()
  const [addCoinsTypes, setAddcoinsTypes] = useState([])
  const [reasons, setReasons] = useState([])

  const validity_date_str = coinSummary?.expires_at
  const validity_date = dayjs(validity_date_str)?.format('D MMM, YYYY')

  const new_validity_date = dayjs(coinSummary?.new_wallet_validity).format(
    'D MMM, YYYY'
  )

  const onReferralCodeToggle = (e) => {
    setFieldValue('use_referral_code', e.target.checked)
  }

  React.useEffect(() => {
    if (reasons.length > 0) {
      if (cartType === 'booking') {
        // If cartType is booking, set the reason to 'Recharge by default'
        setFieldValue('reason', RECHARGE_REASON.RECHARGE)
      }
    }
  }, [reasons])

  React.useEffect(() => {
    ;(async () => {
      const addCoinsConfigResponse = await fetchAddCoinsData()
      if (cartType === 'recharge') {
        const approximateMonthlyCoinResponse = await getCoinCalculation({
          cart_type: cartType,
          parent: formData?.uuid
        })
        setFieldValue(
          'monthly_consumption',
          approximateMonthlyCoinResponse?.data?.total_spending || 0
        )
      } else if (cartType === 'booking') {
        setFieldValue('monthly_consumption', formData?.monthly_consumption || 0)
      }

      const addCoinsConfig = transformRechargeSlabs(
        addCoinsConfigResponse?.results
      )

      const addCoinsTypeList = [...addCoinsConfig]
      setAddcoinsTypes(addCoinsTypeList)

      const reasonsConfig = await fetchReasonsData()
      setReasons(reasonsConfig?.results[0]?.value)
    })()
  }, [formData, cartType])

  React.useEffect(() => {
    if (!isEmpty(values?.addCoinsType)) {
      const type = values?.addCoinsType
      let final_price = 0
      setFieldValue('coins', type.value)
      setFieldValue('list_price', type.list_price)
      if (values?.reason === RECHARGE_REASON.RECHARGE) {
        setFieldValue('discount_percentage', type.discount)
      }
      const admission_fee = values?.admission_fee || 0

      final_price =
        type.value - type.discount * 0.01 * type.value + admission_fee

      setFieldValue('actual_amount', final_price)
      setFieldValue('gst_amount', final_price + 0.18 * final_price)
      setFieldValue(
        'use_referral_code',
        values.use_referral_code && type.key !== 'Custom'
      )
    }
  }, [values?.addCoinsType, values?.admission_fee])

  React.useEffect(() => {
    if (values?.coins && values?.addCoinsType?.key == 'Custom') {
      const coins_value = parseInt(values?.coins) || 0

      setFieldValue('coins', coins_value)
      setFieldValue('list_price', coins_value)

      const discount_percentage_custom = discountPercentCalculator(
        coins_value,
        addCoinsTypes
      )
      if (values?.reason === RECHARGE_REASON.RECHARGE) {
        setFieldValue('discount_percentage', discount_percentage_custom)
      }
      const admission_fee = values?.admission_fee || 0
      const final_price =
        values?.coins -
        values?.discount_percentage * 0.01 * values?.coins +
        admission_fee
      setFieldValue('actual_amount', final_price)
      setFieldValue('gst_amount', final_price + 0.18 * final_price)
    }
  }, [values?.coins, values?.admission_fee])

  React.useEffect(() => {
    if (
      values?.discount_percentage >= 0 &&
      values?.discount_percentage <= 100
    ) {
      const price = parseFloat(values?.list_price) || 0
      const discount_percentage = parseFloat(values?.discount_percentage) || 0
      const admission_fee = values?.admission_fee || 0

      setFieldValue('discount_percentage', discount_percentage)
      const final_price =
        price - discount_percentage * 0.01 * price + admission_fee
      setFieldValue('actual_amount', final_price)

      setFieldValue('gst_amount', final_price + 0.18 * final_price)
    }
  }, [values?.discount_percentage, values?.admission_fee])

  React.useEffect(() => {
    setFieldValue('admission_fee', formData?.admission_fee || 0)
  }, [formData.admission_fee])

  React.useEffect(() => {
    if (!isEmpty(values?.reason)) {
      setFieldValue(
        'use_referral_code',
        values.use_referral_code && values?.reason === RECHARGE_REASON.RECHARGE
      )
    }

    const coins_value = parseFloat(values?.coins) || 0
    const discount_percentage_custom = discountPercentCalculator(
      coins_value,
      addCoinsTypes
    )
    if (values?.reason === RECHARGE_REASON.RECHARGE) {
      setFieldValue('discount_percentage', discount_percentage_custom)
    }
  }, [values.reason])

  const checkboxHandler = (event) => {
    setFieldValue('policy_toggle_check', event.target.checked)
  }

  const validateFee9k =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (!isInt(value) || !isFloat(value)) {
          return 'Please enter valid value'
        }
        if (
          value < 9000 &&
          values.reason === RECHARGE_REASON.RECHARGE &&
          !canRechargeLessThan9k
        ) {
          return 'Minimum recharge amount is 9000'
        }
      })
    }

  const validateReason =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (
          (value === RECHARGE_REASON.REFUND ||
            value === RECHARGE_REASON.WALLET_TRANSFER) &&
          !canRefundOrWalletTransfer
        ) {
          return `You are not authorized to perform a ${values.reason}`
        }
        if (isEmpty(value)) {
          return 'Select a valid value'
        }
      })
    }

  const formElement = React.useMemo(() => {
    return (
      <>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.reason) ? { error: true } : null)}
          required>
          <InputLabel>{'Reason'}</InputLabel>
          <Select
            name={'reason'}
            defaultValue={formData.reason || ''}
            label={'Reason'}
            validate={validateReason({ isRequired: true })}
            disabled={cartType === 'booking'}>
            {reasons.map((key, index) => (
              <MenuItem key={index} value={reasons[index]}>
                {reasons[index]}
              </MenuItem>
            ))}
          </Select>
          {!isEmpty(errors.reason) && (
            <FormHelperText>{errors.reason}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.monthly_consumption) ? { error: true } : null)}
          required>
          <TextField
            name={'monthly_consumption'}
            label={'Approx monthly consumption'}
            variant="outlined"
            defaultValue={formData?.monthly_consumption || 0}
            disabled
            required
            validate={validateFee({ isRequired: true })}
          />
          {!isEmpty(errors.monthly_consumption) && (
            <FormHelperText>{errors.monthly_consumption}</FormHelperText>
          )}
        </FormControl>

        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.addCoinsType) ? { error: true } : null)}
          required>
          <InputLabel>{'Wallet Type'}</InputLabel>
          <Select
            name={'addCoinsType'}
            defaultValue={''}
            label={'Wallet Type'}
            validate={validateNotEmpty({ isRequired: true })}>
            {addCoinsTypes?.map((key, index) => {
              return (
                <MenuItem
                  key={addCoinsTypes[index].key}
                  value={addCoinsTypes[index]}>
                  {addCoinsTypes[index].label}
                </MenuItem>
              )
            })}
          </Select>
          {!isEmpty(errors.addCoinsType) && (
            <FormHelperText>{errors.addCoinsType}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.coins) ? { error: true } : null)}
          required>
          <TextField
            name={'coins'}
            label={'Coins'}
            variant="outlined"
            defaultValue={formData.coins}
            disabled={values?.addCoinsType?.key != 'Custom'}
            required
            validate={validateFee9k({ isRequired: true })}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.list_price) ? { error: true } : null)}
          required>
          <TextField
            name={'list_price'}
            label={'List Price'}
            variant="outlined"
            disabled
            required
            validate={validateFee({ isRequired: true })}
          />
          {!isEmpty(errors.list_price) && (
            <FormHelperText>{errors.list_price}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.discount_percentage) ? { error: true } : null)}
          required>
          <TextField
            name={'discount_percentage'}
            label={'Discount(%)'}
            variant="outlined"
            disabled={values?.reason === RECHARGE_REASON.RECHARGE}
            required
            validate={validateRange({ isRequired: true, min: 0, max: 100 })}
          />
          {!isEmpty(errors.discount_percentage) && (
            <FormHelperText>{errors.discount_percentage}</FormHelperText>
          )}
        </FormControl>
        {!isNil(formData?.payment_summary?.admission_fee) ? (
          <FormControl
            variant="outlined"
            {...(!isEmpty(errors.admission_fee) ? { error: true } : null)}
            required>
            <TextField
              name={'admission_fee'}
              label={'Admission Fee'}
              variant="outlined"
              disabled
              required
              validate={validateFee({ isRequired: true })}
            />
            {!isEmpty(errors.admission_fee) && (
              <FormHelperText>{errors.admission_fee}</FormHelperText>
            )}
          </FormControl>
        ) : null}
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.actual_amount) ? { error: true } : null)}
          required>
          <TextField
            name={'actual_amount'}
            label={'Subtotal'}
            variant="outlined"
            disabled
            required
            validate={validateFee({ isRequired: true })}
          />
          {!isEmpty(errors.actual_amount) && (
            <FormHelperText>{errors.actual_amount}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.gst_amount) ? { error: true } : null)}
          required>
          <TextField
            name={'gst_amount'}
            label={'Final Amount (inclusive of 18% GST)'}
            variant="outlined"
            disabled
            required
            validate={validateFee({ isRequired: true })}
          />
          {!isEmpty(errors.gst_amount) && (
            <FormHelperText>{errors.gst_amount}</FormHelperText>
          )}
        </FormControl>

        {canAddReferralCode(values) ? (
          <>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    type="checkbox"
                    color="primary"
                    defaultChecked={formData?.use_referral_code}
                    checked={values?.use_referral_code}
                    onChange={onReferralCodeToggle}
                    name={'use_referral_code'}
                  />
                }
                label={'Use Referral Code'}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.referral_code) && values.use_referral_code
                ? { error: true }
                : null)}
              required>
              <TextField
                name={'referral_code'}
                label={'Referral Code'}
                variant="outlined"
                disabled={!values?.use_referral_code}
                validate={validateReferralCode({
                  isRequired: values.use_referral_code
                })}
              />
            </FormControl>
          </>
        ) : null}
        {values.reason === RECHARGE_REASON.RECHARGE && (
          <FormControl
            {...(!isEmpty(errors.policy_toggle_check) ? { error: true } : null)}
            required>
            <FormControlLabel
              name="policy_toggle_check"
              classes={{ root: classes.root }}
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  color="primary"
                  onChange={checkboxHandler}
                  defaultChecked={formData.is_policy_acknowledged_alfred}
                  validate={
                    !formData.is_policy_acknowledged_alfred &&
                    validatePolicyAcknowledgment({ isRequired: true })
                  }
                  required
                />
              }
              label={POLICY_ACKNOWLEDGMENT_ALFRED}
              disabled={formData.is_policy_acknowledged_alfred}
            />
            {!isEmpty(errors.policy_toggle_check) && (
              <FormHelperText classes={{ root: classes.errorText }}>
                {errors.policy_toggle_check}
              </FormHelperText>
            )}
          </FormControl>
        )}

        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.notes) ? { error: true } : null)}
          required>
          <TextField
            name={'notes'}
            label={'Additional notes'}
            variant="outlined"
            validate={validateNotEmpty({ isRequired: false })}
          />
          {!isEmpty(errors.notes) && (
            <FormHelperText>{errors.notes}</FormHelperText>
          )}
        </FormControl>

        {formData?.show_reason_field &&
        values?.reason === RECHARGE_REASON.RECHARGE ? (
          <FormControl
            variant="outlined"
            {...(!isEmpty(errors.reason_for_using_alfred)
              ? { error: true }
              : null)}
            required>
            <TextField
              name={'reason_for_using_alfred'}
              label={'Reason for using Alfred instead of the App'}
              variant="outlined"
              required
              validate={validateNotEmpty({ isRequired: true })}
            />
          </FormControl>
        ) : null}
        {!isEmpty(formData?.payment_summary) ? (
          <Styles.ContentContainer>
            <Styles.Subheading>payment summary</Styles.Subheading>
            <Styles.PaymentSummary>
              <Styles.SummaryItem>
                <span>amount</span>
                <span>
                  ₹
                  {numberWithCommas(
                    parseFloat(values?.list_price || 0).toFixed(2)
                  )}
                </span>
              </Styles.SummaryItem>
              {values?.discount_percentage > 0 && (
                <>
                  <Styles.SummaryItem>
                    <span>{values?.discount_percentage}% discount</span>
                    <Styles.CoinsContainer>
                      <div style={{ color: 'red' }}>
                        -₹
                        {numberWithCommas(
                          parseFloat(
                            values?.discount_percentage *
                              0.01 *
                              values?.coins || 0
                          ).toFixed(2)
                        )}
                      </div>
                    </Styles.CoinsContainer>
                  </Styles.SummaryItem>
                  <Styles.Separator></Styles.Separator>

                  <Styles.SummaryItem>
                    <span>discounted amount</span>
                    <span>
                      ₹
                      {numberWithCommas(
                        parseFloat(
                          values?.actual_amount - values?.admission_fee || 0
                        ).toFixed(2)
                      )}
                    </span>
                  </Styles.SummaryItem>
                </>
              )}
              {formData?.payment_summary?.admission_fee ? (
                <>
                  <Styles.SummaryItem>
                    <span>one-time admission fee</span>
                    <Styles.CoinsContainer>
                      <div style={{}}>
                        ₹
                        {numberWithCommas(
                          parseFloat(
                            formData?.payment_summary?.admission_fee
                          ).toFixed(2)
                        )}
                      </div>
                    </Styles.CoinsContainer>
                  </Styles.SummaryItem>
                  <Styles.Separator></Styles.Separator>

                  <Styles.SummaryItem>
                    <span>subtotal</span>
                    <span>
                      ₹
                      {numberWithCommas(
                        parseFloat(values?.actual_amount || 0).toFixed(2)
                      )}
                    </span>
                  </Styles.SummaryItem>
                </>
              ) : null}

              <Styles.SummaryItem>
                <span>18% gst</span>
                <span>
                  ₹
                  {numberWithCommas(
                    parseFloat(0.18 * values?.actual_amount || 0).toFixed(2)
                  )}
                </span>
              </Styles.SummaryItem>
              <Styles.TotalPayable>
                <span>total payable</span>
                <span>
                  ₹
                  {numberWithCommas(
                    parseFloat(values?.gst_amount || 0).toFixed(2)
                  )}
                </span>
              </Styles.TotalPayable>
            </Styles.PaymentSummary>
          </Styles.ContentContainer>
        ) : null}
      </>
    )
  }, [values, formData, errors, addCoinsTypes, reasons])

  return (
    <>
      {!isNil(validity_date_str) ? (
        <div style={{ lineHeight: 0.5 }}>
          Current wallet validity: {validity_date}
        </div>
      ) : null}
      <div style={{ lineHeight: 0.5 }}>
        Wallet validity after recharge: {new_validity_date}
      </div>
      <br />
      {formElement}
    </>
  )
}

export default TopupForm
