import React from 'react'
import 'react-quill/dist/quill.snow.css'

import Linkify from 'react-linkify'

import { quillSanitizeWhitelist } from 'app/config'
import { sanitize } from 'app/utils'

import * as styles from './styles'

const MessageText = ({ text }) => {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(text)

  return (
    <styles.Container>
      {!isHTML ? (
        <Linkify>{text}</Linkify>
      ) : (
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: sanitize(text, quillSanitizeWhitelist)
          }}
        />
      )}
    </styles.Container>
  )
}

export default MessageText
